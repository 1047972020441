import { Typography } from '@mui/material'
import {
  FlexGrowCol, FlexGrowRow, LinkEx,
} from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { SimpleAccordionCard } from '../../../common/index.ts'
import { TzeroDepositError, TzeroDepositSuccess } from './img/index.ts'

export const DepositNotificationOptionsAccordion: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <FlexGrowCol alignItems="stretch">
      <Typography variant="body1" textAlign="left" gutterBottom>
        Once you finish the request for a deposit, a notification will populate with the status of your deposit request.
      </Typography>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          headline="Success"
          desc={(
            <FlexGrowCol alignItems="stretch">
              <FlexGrowCol
                sx={{
                  backgroundImage: `url(${TzeroDepositSuccess})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  height: { md: '500px', xs: '300px' },
                  width: '100%',
                }}
              >
              </FlexGrowCol>
              <Typography variant="body2" textAlign="left" gutterBottom>
                Your holdings in XY Labs are now available in your tZERO Markets account.
              </Typography>
            </FlexGrowCol>
          )}
          key="Success"
          expanded={expanded === 'Success'}
          onChange={handleChange('Success')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          dark={false}
          headline="Pending"
          desc={(
            <Typography variant="body2" textAlign="left" gutterBottom>
              You have a pending deposit that is in process and will receive an email notification of the status of your deposit request.
            </Typography>
          )}
          key="Pending"
          expanded={expanded === 'Pending'}
          onChange={handleChange('Pending')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          headline="Error"
          desc={(
            <FlexGrowCol>
              <FlexGrowRow
                alignItems="stretch"
                sx={{
                  backgroundImage: `url(${TzeroDepositError})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  height: { md: '300px', xs: '200px' },
                  width: '100%',
                }}
              >
              </FlexGrowRow>
              <Typography variant="body2" textAlign="left" gutterBottom>
                An error occurred initiating your deposit and you will be prompted to try again. If you continue to experience issues, please contact
                {' '}
                <LinkEx target="_blank" href="mailto:support@tzero.com">
                  support@tzero.com
                </LinkEx>
                .
              </Typography>
            </FlexGrowCol>
          )}
          key="Error"
          expanded={expanded === 'Error'}
          onChange={handleChange('Error')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
    </FlexGrowCol>
  )
}
