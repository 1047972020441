import { MenuRounded } from '@mui/icons-material'
import {
  IconButton, SwipeableDrawer, useMediaQuery, useTheme,
} from '@mui/material'
import {
  ButtonEx, FlexCol, FlexGrowCol,
} from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { MobileAccordion } from './MobileAccordion.tsx'
import { navbarData } from './navbarData.tsx'
const MobileMenu: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [accordionOpenItem, setAccordionOpenItem] = useState(-1)
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }
    setMenuOpen(open)
  }

  return (
    <FlexCol>
      <FlexCol sx={{ display: { md: 'none', xs: 'flex' } }} paddingLeft={2} display={isMobile ? 'flex ' : 'none'}>
        <IconButton aria-label="menu-button" onClick={toggleDrawer(!menuOpen)}>
          <MenuRounded />
        </IconButton>
      </FlexCol>
      <FlexCol sx={{ display: { md: 'none', xs: 'flex' } }}>
        <SwipeableDrawer anchor="right" open={menuOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} variant="temporary" elevation={0}>
          <FlexGrowCol justifyContent="space-between" padding={1}>
            <FlexCol width="250px">
              {navbarData?.map((section, index) => {
                return (
                  <FlexCol key={index} justifyContent="flex-start" alignItems="stretch" width="250px" marginBottom={1}>
                    {section.sectionItems
                      ? (
                          <MobileAccordion
                            index={index}
                            name={section.name}
                            to={section.to}
                            sectionItems={section.sectionItems}
                            key={index}
                            dark={!(index % 2)}
                            expanded={accordionOpenItem === index}
                            onChange={(event, expanded) => setAccordionOpenItem(expanded ? index : -1)}
                          />
                        )
                      : null}
                  </FlexCol>
                )
              })}
              <ButtonEx color="primary" to={{ pathname: '/get-started' }} variant="contained" paddingY={1} size="large" fullWidth marginTop={3}>
                Get Started
              </ButtonEx>
            </FlexCol>
          </FlexGrowCol>
        </SwipeableDrawer>
      </FlexCol>
    </FlexCol>
  )
}

export default MobileMenu
