import type { Place, PostalAddress } from '@xylabs/sdk-react'

const FoundingLocationCity: PostalAddress = {
  '@type': 'PostalAddress',
  'addressCountry': 'US',
  'addressLocality': 'San Diego',
  'addressRegion': 'California',
}

const FoundingLocation: Place = {
  '@type': 'Place',
  'address': FoundingLocationCity,
}

export default FoundingLocation
