import { ArrowForwardRounded } from '@mui/icons-material'
import {
  Container, Divider, Grid, Typography,
} from '@mui/material'
import type { FlexBoxProps } from '@xylabs/sdk-react'
import {
  ButtonEx, FlexGrowCol, FlexGrowRow,
} from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

const Team: React.FC<FlexBoxProps> = () => {
  return (
    <SectionSpacingRow>
      <Container>
        <FlexGrowRow sx={{ paddingY: { md: 5, xs: 2 } }} width="100%">
          <Grid container>
            <Grid item xs={12}>
              <FlexGrowCol width="100%">
                <Typography variant="h2" gutterBottom>
                  Who Are We?
                </Typography>
                <Divider />
                <Typography variant="body1" gutterBottom>
                  The XY Labs team is full of people who love to learn, experiment, and grow!
                </Typography>
                <ButtonEx color="secondary" variant="contained" to="/company/team" endIcon={<ArrowForwardRounded />} margin={2}>
                  Meet the Team
                </ButtonEx>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </FlexGrowRow>
      </Container>
    </SectionSpacingRow>
  )
}

export default Team
