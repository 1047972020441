import {
  Container, Grid, Step, StepButton, StepContent, Stepper, Typography,
  useTheme,
} from '@mui/material'
import {
  ButtonEx, FlexGrowCol, FlexRow,
} from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import type { RefObject } from 'react'
import React, {
  useEffect, useRef, useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { stepsData } from './InstructionsData.tsx'

export const TzeroInstructions: React.FC = () => {
  const anchors: Record<string, RefObject<HTMLDivElement>> = { tzeroSignupStep1: useRef<HTMLDivElement>(null) }
  const location = useLocation()
  const anchor = anchors[location.hash.slice(1)]

  useEffect(() => {
    if (anchor?.current) {
      anchor?.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [anchor])

  const [activeStep, setActiveStep] = useState(0)

  const handleStepChange = (step: number) => () => {
    setActiveStep(step)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    globalThis.location.href = '#tzeroSignupStep1'
    setActiveStep(0)
  }

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h4" textAlign="center" gutterBottom sx={{ paddingBottom: { md: 4, xs: 2 } }}>
              How to make a tZERO Markets Account
            </Typography>
            <FlexGrowCol alignItems="stretch">
              <Stepper orientation="vertical" nonLinear activeStep={activeStep} id="tzeroSignupStep1" ref={anchors.tzeroSignupStep1}>
                {stepsData.map((step, index) => (
                  <Step key={step.label}>
                    <StepButton color="inherit" onClick={handleStepChange(index)}>
                      <Typography variant="h6">{step.label}</Typography>
                    </StepButton>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <FlexRow sx={{ mb: 2 }} width="100%" justifyContent="flex-start">
                        <ButtonEx variant="contained" onClick={index === stepsData.length - 1 ? handleReset : handleNext} sx={{ mr: 1, mt: 1 }}>
                          {index === stepsData.length - 1 ? 'Start from Beginning' : 'Next'}
                        </ButtonEx>
                        <ButtonEx disabled={index === 0} onClick={handleBack} to="#tzeroSignupStep1" sx={{ mr: 1, mt: 1 }}>
                          Back
                        </ButtonEx>
                      </FlexRow>
                      {step.image
                        ? (
                            <FlexGrowCol
                              sx={{
                                backgroundImage: `url(${step.image})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                height: { md: '500px', xs: '300px' },
                              }}
                            >
                            </FlexGrowCol>
                          )
                        : null}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}
