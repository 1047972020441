import type {
  FunnelStartedFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { EmptyObject } from '@xylabs/sdk-js'

import type CustomProperties from './CustomProperties.tsx'
import CoinEvents from './Xy/index.ts'

class XyEventHandler<T extends EmptyObject> extends UserEventHandler<T> {
  private events = new CoinEvents<CustomProperties>()

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    await this.events.viewContent().send(fields)
  }
}

export default XyEventHandler
