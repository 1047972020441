/* eslint-disable @stylistic/max-len */
import {
  Container, Divider,
  Typography, useMediaQuery, useTheme,
} from '@mui/material'
import { ButtonEx, FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero, CommonImages } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'

const DataismPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BasePage name="Dataism">
      <BasicHero
        title="Dataism"
        desc="The relationship between a human's data, their physical footprint, and their digital footprint."
        backgroundImage={isMobile ? '' : CommonImages.xyoImage}
        centeredAlways
      />
      <Divider />
      <SectionSpacingRow>
        <SectionSpacingRow>
          <Container>
            <FlexGrowCol alignItems="flex-start">
              <Typography variant={isMobile ? 'h3' : 'h2'} gutterBottom textAlign="left">
                A new philosophy in the era of big data.
              </Typography>
              <Typography variant="body1" gutterBottom textAlign="left">
                Dataism is often described as a new philosophy in the wake of the era of Big Data.
              </Typography>
              <Typography variant="body1" gutterBottom textAlign="left">
                Some argue that dataism includes the belief that the value of an entity comes from its ability to contribute to data processing.
                Others view political and social structures as dataist entities, too. In nearly every case, Dataism is described as the belief that
                big data, and the analysis of that data, will be one of the most integral parts in the quest towards thoughtful innovation and
                futuristic invention.
              </Typography>
              <Typography variant="body1" gutterBottom textAlign="left">
                At XY Labs, the ideals behind Dataism drive our own innovation and product creation.
              </Typography>
              <ButtonEx marginTop={1} variant="contained" color="primary" href="https://dataism.org/" target="_blank">Visit Dataism.org</ButtonEx>
            </FlexGrowCol>
          </Container>
        </SectionSpacingRow>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <SectionSpacingRow>
          <Container>
            <FlexGrowCol alignItems="flex-start">
              <Typography variant={isMobile ? 'h3' : 'h2'} gutterBottom textAlign="left">
                Dataism and XY Labs
              </Typography>
              <Typography variant="body1" gutterBottom textAlign="left">
                At XY Labs, Dataism isn&apos;t just a guiding principle—it&apos;s the soul of every product we create. Dataism shapes how we design, build, and improve our technologies, always with the understanding that data is as organic as life itself. We see data as an extension of human experience, a part of each individual&apos;s identity that deserves respect, transparency, and purpose. From connecting the real and virtual worlds to empowering users to control and benefit from their data, our commitment to Dataism ensures that data flows naturally and ethically through every touchpoint in our ecosystem.
              </Typography>
              <ButtonEx marginTop={1} variant="contained" color="primary" href="https://dataism.org/pillars" target="_blank">Pillars of Dataism</ButtonEx>
            </FlexGrowCol>
          </Container>
        </SectionSpacingRow>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <SectionSpacingRow>
          <Container>
            <FlexGrowCol alignItems="flex-start">
              <Typography variant={isMobile ? 'h3' : 'h2'} gutterBottom textAlign="left">
                Data-Empowered Products for a Trusted Data Economy
              </Typography>
              <Typography variant="body1" gutterBottom textAlign="left">
                Data holds immense potential for transforming our everyday lives, but this transformation is only valuable if it&apos;s rooted in trust and security. At XY Labs, we leverage Dataism&apos;s pillars—such as openness, algorithmic governance, and continuous optimization—to make data-driven technologies that are accessible and beneficial for everyone. Whether it&apos;s through smart algorithms that refine the user experience or transparent practices that secure user control, our products are designed to foster a trusted data economy where users are confident in their ownership and control over their data. Dataism fuels our mission to create data tools that feel as natural and secure as the physical world.
              </Typography>
              <ButtonEx marginTop={1} variant="contained" color="primary" href="https://dataism.org" target="_blank">Visit Dataism.org</ButtonEx>
            </FlexGrowCol>
          </Container>
        </SectionSpacingRow>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default DataismPage
