import { Divider } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { useIsMobile } from '@xyo-network/react-shared'
import React from 'react'

import { TzeroListingBar } from '../../common/index.ts'
import { OrganizationRichResult } from '../../RichResults/index.ts'
import BasePage from '../BasePage.tsx'
import { HomePageDataSection } from './Data.tsx'
import { HomePageHero } from './Hero/index.ts'
import { InvestorSection } from './InvestorSection.tsx'
import { ProductsSection } from './ProductsSection.tsx'
import { XYOSection } from './XYOSection.tsx'

const onClickIdScrollIntoView = (elementId: string) => () => {
  const section = document.querySelector(elementId)
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

export const HomePage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <BasePage name="Home" positioningAppbar={false}>
      <OrganizationRichResult />
      <FlexGrowCol height="100vh" maxHeight="1000px" alignItems="stretch">
        <HomePageHero
          title=""
          title2="Data Made for Everyone"
          animatedText={['Simple', 'Trustless', 'Self-Sovereign', 'Readable', 'Standardized', 'Beautiful', 'Actionable', 'Consensusless']}
          desc="XY Labs connects data between the real and digital worlds through blockchain, IoT, and
        cryptographic products. XYO, our self-sovereign data platform, is our key product."
          button1Text="Learn More"
          buttonOnClick={onClickIdScrollIntoView('#dataCore')}
        />
        {isMobile ? null : <TzeroListingBar />}
      </FlexGrowCol>
      {isMobile
        ? (
            <FlexGrowCol>
              <TzeroListingBar />
            </FlexGrowCol>
          )
        : null}
      <div id="dataCore" />
      <HomePageDataSection />
      <div id="products" />
      <ProductsSection />
      <Divider />
      <InvestorSection />
      <Divider />
      <div id="xyoProduct" />
      <XYOSection />
    </BasePage>
  )
}
