import {
  Container, Grid, Typography,
} from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import { asButtonHrefOrToProps, ButtonEx } from '@xylabs/react-button'
import type { BusyBoxProps, FlexBoxProps } from '@xylabs/react-flexbox'
import {
  FlexGrowCol, FlexGrowRow, FlexRow,
} from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import { useIsMobile } from '@xyo-network/react-shared'
import type { ReactElement, ReactNode } from 'react'
import React from 'react'

import { PlantsRightParallax } from './Parallax/index.ts'

export interface BasicHeroProps extends FlexBoxProps {
  backgroundColor?: string
  backgroundImage?: string
  button1Href?: string
  button1Text?: string
  button1To?: string
  button2Href?: string
  button2Text?: string
  button2To?: string
  centeredAlways?: boolean
  desc: string
  parallaxBackground?: ReactNode
  subLinkIcon?: ReactElement
  subLinkPath?: string
  subLinkText1?: string
  subLinkText2?: string
  sx?: Record<string, string>
  title: string
}

interface SubLinkSectionProps extends BusyBoxProps {
  flexStartAlignment?: boolean
  subLinkIcon?: ReactElement
  subLinkPath?: string
  subLinkText1?: string
  subLinkText2?: string
}

type ButtonSectionProps = ButtonExProps & {
  buttonText?: string
}
const SubLinkSection: React.FC<SubLinkSectionProps> = ({
  subLinkText1, subLinkText2, subLinkPath, subLinkIcon, flexStartAlignment,
}) => {
  return (
    <FlexGrowRow
      width="100%"
      sx={{ flexDirection: { md: 'row', xs: 'column' }, justifyContent: { md: flexStartAlignment ? 'flex-start' : 'center', xs: 'center' } }}
    >
      <FlexRow>
        {subLinkIcon
          ? (
              <span>
                {subLinkIcon}
&nbsp;
              </span>
            )
          : null}
        <Typography variant="body1">
          {subLinkText1}
&nbsp;
        </Typography>
      </FlexRow>
      <LinkEx href={subLinkPath} underline="always" target="_blank" color="inherit">
        <Typography variant="body1">{subLinkText2}</Typography>
      </LinkEx>
    </FlexGrowRow>
  )
}

const ButtonSection: React.FC<ButtonSectionProps> = ({ buttonText, ...props }) => {
  const isMobile = useIsMobile()
  return (
    <ButtonEx
      fullWidth={true}
      marginTop={1}
      marginBottom={1}
      marginRight={isMobile ? 2 : 1}
      marginLeft={isMobile ? 2 : 0}
      target={props.href ?? '_blank'}
      color="primary"
      variant="contained"
      paddingX={3}
      sx={{ display: props.href || props.to ? 'flex' : 'none' }}
      {...props}
    >
      {buttonText}
    </ButtonEx>
  )
}

export const BasicHero: React.FC<BasicHeroProps> = ({
  backgroundImage,
  title,
  backgroundColor,
  centeredAlways,
  desc,
  subLinkText1,
  subLinkText2,
  subLinkPath,
  button1Text,
  button2Text,
  button2To,
  button1To,
  button2Href,
  button1Href,
  subLinkIcon,
  parallaxBackground,
  sx,
  ...props
}) => {
  const containsButtons: boolean = button1Href || button1To || button2Href || button2To ? true : false
  const mediumSizeAlignment = centeredAlways ? 'center' : 'flex-start'
  return (
    <FlexGrowCol
      sx={{
        backgroundColor: backgroundColor ?? '',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: {
          lg: 'bottom', md: 'center bottom', xs: 'bottom',
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: {
          md: '500px',
          sm: '400px',
          xs: '200px',
        },
        ...sx,
      }}
      {...props}
    >
      {parallaxBackground
        ? (
            <FlexGrowCol
              sx={{
                display: { md: 'flex', xs: 'none' }, height: { xs: '100%' }, maxHeight: '1000px',
              }}
              width="100%"
              position="absolute"
              bottom="0px"
            >
              <PlantsRightParallax />
            </FlexGrowCol>
          )
        : null}
      <Container>
        <Grid container alignItems="center" sx={{ justifyContent: { md: mediumSizeAlignment, xs: 'center' } }}>
          <Grid item xs={12} sm={8} md={backgroundImage ? 6 : 8} lg={backgroundImage ? 6 : 8}>
            <FlexGrowCol paddingY={2} sx={{ alignItems: { md: mediumSizeAlignment, xs: 'center' } }}>
              <Typography variant="h1" component="h1" gutterBottom sx={{ textAlign: { md: mediumSizeAlignment, xs: 'center' } }}>
                {title
                  ? <span>{`${title} `}</span>
                  : null}
              </Typography>
              <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: { md: mediumSizeAlignment, xs: 'center' } }}>
                {desc}
              </Typography>
              {containsButtons ?? (
                <FlexGrowRow sx={{ flexDirection: { lg: 'row', xs: 'column' } }} width="100%" marginTop={1}>
                  <ButtonSection {...asButtonHrefOrToProps({ href: button1Href, to: button1To })} buttonText={button1Text} />
                  <ButtonSection {...asButtonHrefOrToProps({ href: button2Href, to: button2To })} buttonText={button2Text} variant="outlined" />
                </FlexGrowRow>
              )}
              <SubLinkSection
                sx={{ alignItems: { md: mediumSizeAlignment, xs: 'center' } }}
                subLinkIcon={subLinkIcon}
                subLinkText1={subLinkText1}
                subLinkText2={subLinkText2}
                subLinkPath={subLinkPath}
                flexStartAlignment={
                  centeredAlways
                    ? false
                    : backgroundImage
                      ? true
                      : true
                }
              />
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </FlexGrowCol>
  )
}
