import { WorkRounded } from '@mui/icons-material'
import { Divider } from '@mui/material'
import { BasicHero } from '@xyo-network/react-shared'
import React from 'react'

import { HiringBarAlert } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'
import XyoExplained from './Explained.tsx'
import XyoQuote from './Quote.tsx'
import XyoVideo from './Video.tsx'
import WhitePaper from './WhitePaper.tsx'

const XyoNetworkPage: React.FC = () => {
  return (
    <BasePage name="Xyo Network">
      <BasicHero
        title="XYO Network"
        desc="A Node-Based Technology for Sovereign & Permanent Data"
        button1Text="XYO Network Website"
        button1Href="https://xyo.network/"
        button2Text="Network Components"
        button2Href="https://xyo.network/network/"
        subLinkText1="Want to partner with XYO Network?"
        subLinkPath="https://xyo.network/partners"
        subLinkText2="Contact the team here"
        subLinkIcon={<WorkRounded />}
      />
      <Divider />
      <XyoExplained />
      <XyoQuote />
      <XyoVideo />
      <WhitePaper />
      <HiringBarAlert />
    </BasePage>
  )
}

export default XyoNetworkPage
