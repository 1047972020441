import {
  XYLabs_Logo_Full_Black_PNG,
  XYLabs_Logo_Full_Black_SVG,
  XYLabs_Logo_Full_BrandBlack_PNG,
  XYLabs_Logo_Full_BrandBlack_SVG,
  XYLabs_Logo_Full_BrandWhite_PNG,
  XYLabs_Logo_Full_BrandWhite_SVG,
  XYLabs_Logo_Full_White_PNG,
  XYLabs_Logo_Full_White_SVG,
  XYLabs_Logo_Icon_Black_PNG,
  XYLabs_Logo_Icon_Black_SVG,
  XYLabs_Logo_Icon_BrandColor_PNG,
  XYLabs_Logo_Icon_BrandColor_SVG,
  XYLabs_Logo_Icon_White_PNG,
  XYLabs_Logo_Icon_White_SVG,
  XYLabs_Logo_Simple_Black_PNG,
  XYLabs_Logo_Simple_Black_SVG,
  XYLabs_Logo_Simple_BrandBlack_PNG,
  XYLabs_Logo_Simple_BrandBlack_SVG,
  XYLabs_Logo_Simple_BrandWhite_PNG,
  XYLabs_Logo_Simple_BrandWhite_SVG,
  XYLabs_Logo_Simple_White_PNG,
  XYLabs_Logo_Simple_White_SVG,
} from './img/index.ts'

export const fileTypes = [
  {
    label: 'PNG',
    value: 'png',
  },
  {
    label: 'SVG',
    value: 'svg',
  },
]

export const colors = [
  {
    label: 'Brand Colors (Black Text)',
    value: 'brandBlack',
  },
  {
    label: 'Brand Colors (White Text)',
    value: 'brandWhite',
  },
  {
    label: 'Black',
    value: 'black',
  },
  {
    label: 'White',
    value: 'white',
  },
]

export interface logoColorOptions {
  [key: string]: string
}

export interface logoAssetProps {
  [key: string]: logoColorOptions
}

export interface assetOptionsProps {
  [key: string]: logoAssetProps
}

export const assetOptions: assetOptionsProps = {
  full: {
    png: {
      black: XYLabs_Logo_Full_Black_PNG,
      brandBlack: XYLabs_Logo_Full_BrandBlack_PNG,
      brandWhite: XYLabs_Logo_Full_BrandWhite_PNG,
      white: XYLabs_Logo_Full_White_PNG,
    },
    svg: {
      black: XYLabs_Logo_Full_Black_SVG,
      brandBlack: XYLabs_Logo_Full_BrandBlack_SVG,
      brandWhite: XYLabs_Logo_Full_BrandWhite_SVG,
      white: XYLabs_Logo_Full_White_SVG,
    },
  },
  icon: {
    png: {
      black: XYLabs_Logo_Icon_Black_PNG,
      brandBlack: XYLabs_Logo_Icon_BrandColor_PNG,
      brandWhite: XYLabs_Logo_Icon_BrandColor_PNG,
      white: XYLabs_Logo_Icon_White_PNG,
    },
    svg: {
      black: XYLabs_Logo_Icon_Black_SVG,
      brandBlack: XYLabs_Logo_Icon_BrandColor_SVG,
      brandWhite: XYLabs_Logo_Icon_BrandColor_SVG,
      white: XYLabs_Logo_Icon_White_SVG,
    },
  },
  simple: {
    png: {
      black: XYLabs_Logo_Simple_Black_PNG,
      brandBlack: XYLabs_Logo_Simple_BrandBlack_PNG,
      brandWhite: XYLabs_Logo_Simple_BrandWhite_PNG,
      white: XYLabs_Logo_Simple_White_PNG,
    },
    svg: {
      black: XYLabs_Logo_Simple_Black_SVG,
      brandBlack: XYLabs_Logo_Simple_BrandBlack_SVG,
      brandWhite: XYLabs_Logo_Simple_BrandWhite_SVG,
      white: XYLabs_Logo_Simple_White_SVG,
    },
  },
}
