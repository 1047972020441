import { Container } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'
import { SalesTermsPages } from './img/index.ts'

const SaleTermsPage: React.FC = () => {
  return (
    <BasePage name="Sales Terms">
      <BasicHero title="Sales Terms" desc="" />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            {SalesTermsPages.map((src, index) => (
              <img key={index} src={src} alt={`Sales Terms Page ${index + 1}`} width="100%" />
            ))}
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default SaleTermsPage
