import type { TypographyProps } from '@mui/material'
import { Fade } from '@mui/material'
import React, { useEffect, useState } from 'react'
export interface AnimatedTextProps extends TypographyProps {
  text?: string[]
}

export const AnimatedText: React.FC<AnimatedTextProps> = ({ text }) => {
  const [activeTextKey, setActiveTextKey] = useState(0)
  const [animationBegin, setAnimationBegin] = useState(true)
  const fadeInterval = 2500

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fadeTimeout: any = setInterval(() => {
      setAnimationBegin(!animationBegin)
    }, fadeInterval)

    return () => clearInterval(fadeTimeout)
  }, [animationBegin])

  useEffect(() => {
    const textLength = text?.length
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fadeTimeout: any = setInterval(() => {
      setActiveTextKey(activeTextKey == textLength ? 0 : activeTextKey + 1)
    }, fadeInterval * 2)

    return () => clearInterval(fadeTimeout)
  }, [activeTextKey, text?.length])

  return (
    <Fade in={animationBegin} timeout={2500}>
      <span>{text?.[activeTextKey]}</span>
    </Fade>
  )
}
