import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import floorPlantsRight1 from './image/floor-plants-right1.svg'
import floorPlantsRight2 from './image/floor-plants-right2.svg'
import floorPlantsRight3 from './image/floor-plants-right3.svg'
import floorPlantsRight4 from './image/floor-plants-right4.svg'
import floorPlantsRight5 from './image/floor-plants-right5.svg'
import floorPlantsRight6 from './image/floor-plants-right6.svg'
import floorPlantsWideLeft1 from './image/floor-plants-wide-left1.svg'
import floorPlantsWideLeft2 from './image/floor-plants-wide-left2.svg'
import floorPlantsWideLeft3 from './image/floor-plants-wide-left3.svg'
import floorPlantsWideLeft4 from './image/floor-plants-wide-left4.svg'
import floorPlantsWideRight1 from './image/floor-plants-wide-right1.svg'
import floorPlantsWideRight2 from './image/floor-plants-wide-right2.svg'
import floorPlantsWideRight3 from './image/floor-plants-wide-right3.svg'
import floorPlantsWideRight4 from './image/floor-plants-wide-right4.svg'
import floorPlantsWideRight5 from './image/floor-plants-wide-right5.svg'
import legoLeft1 from './image/legos-left1.svg'
import legoLeft2Floating from './image/legos-left2-floating.svg'
import legoLeft3 from './image/legos-left3.svg'
import legoLeft4 from './image/legos-left4.svg'
import legoLeft5 from './image/legos-left5.svg'
import legoLeft6Floating from './image/legos-left6-floating.svg'
import legoLeft7 from './image/legos-left7.svg'
import legoLeft8 from './image/legos-left8.svg'
import legoLeft9 from './image/legos-left9.svg'

export const PlantsWideParallax: React.FC = () => {
  return (
    <ParallaxBanner
      style={{ height: '100%' }}
      layers={[
        {
          expanded: false,
          image: floorPlantsWideLeft1,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, -20],
        },
        {
          expanded: false,
          image: floorPlantsWideLeft2,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [5, -17],
        },
        {
          expanded: false,
          image: floorPlantsWideLeft3,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [5, -8],
        },
        {
          expanded: false,
          image: floorPlantsWideLeft4,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [5, -7],
        },
        {
          expanded: false,
          image: floorPlantsWideRight1,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [-5, 10],
        },
        {
          expanded: false,
          image: floorPlantsWideRight2,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [-5, 9],
        },
        {
          expanded: false,
          image: floorPlantsWideRight3,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [-5, 8],
        },
        {
          expanded: false,
          image: floorPlantsWideRight4,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [-5, 7],
        },
        {
          expanded: false,
          image: floorPlantsWideRight5,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [-5, 6],
        },
      ]}
    />
  )
}

export const PlantsRightParallax: React.FC = () => {
  return (
    <ParallaxBanner
      style={{ height: '100%' }}
      layers={[
        {
          expanded: false,
          image: floorPlantsRight1,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, 25],
        },
        {
          expanded: false,
          image: floorPlantsRight3,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, 22],
        },
        {
          expanded: false,
          image: floorPlantsRight2,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, 19],
        },
        {
          expanded: false,
          image: floorPlantsRight4,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, 16],
        },
        {
          expanded: false,
          image: floorPlantsRight5,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, 13],
        },
        {
          expanded: false,
          image: floorPlantsRight6,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
          },
          translateX: [0, 12],
        },
      ]}
    />
  )
}

export const LegoLeftParallax: React.FC = () => {
  return (
    <ParallaxBanner
      style={{ height: '100%' }}
      layers={[
        {
          expanded: false,
          image: legoLeft1,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [0, 0],
        },
        {
          expanded: false,
          image: legoLeft2Floating,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [10, -10],
        },
        {
          expanded: false,
          image: legoLeft3,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateX: [0, 0],
        },
        {
          expanded: false,
          image: legoLeft4,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [0, 0],
        },
        {
          expanded: false,
          image: legoLeft5,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [0, 0],
        },
        {
          expanded: false,
          image: legoLeft6Floating,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [5, -30],
        },
        {
          expanded: false,
          image: legoLeft7,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [0, 0],
        },
        {
          expanded: false,
          image: legoLeft8,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [0, -5],
        },
        {
          expanded: false,
          image: legoLeft9,
          shouldAlwaysCompleteAnimation: true,
          style: {
            backgroundPosition: 'left bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'contain',
          },
          translateY: [0, 0],
        },
      ]}
    />
  )
}
