import { ExpandMoreRounded } from '@mui/icons-material'
import type { AccordionProps } from '@mui/material'
import {
  Accordion, AccordionDetails, AccordionSummary, alpha, Typography, useMediaQuery, useTheme,
} from '@mui/material'
import type { Override } from '@xylabs/sdk-js'
import { asButtonHrefOrToProps, ButtonEx } from '@xylabs/sdk-react'
import type { Key, ReactNode } from 'react'
import React from 'react'
import type { To } from 'react-router-dom'
interface SimpleAccordionCardAdditionalProps extends AccordionProps {
  dark?: boolean
  desc?: ReactNode
  expandedKey?: Key
  headline: ReactNode
  href?: string
  linkText?: string
  onChangeExpandKey?: (expanded: string | null) => void
  to?: To
}

interface OptionalChildren {
  children?: ReactNode
}

// this makes the requirement for children from AccordionProps go away
export type SimpleAccordionCardProps = Override<SimpleAccordionCardAdditionalProps, OptionalChildren>

export const SimpleAccordionCard: React.FC<SimpleAccordionCardProps> = ({
  id,
  headline,
  desc,
  linkText,
  to,
  href,
  expandedKey,
  dark = false,
  onChangeExpandKey,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    onChangeExpandKey?.(isExpanded ? (id ?? null) : null)
  }

  return (
    <Accordion
      expanded={expandedKey === id}
      onChange={handleChange}
      elevation={0}
      style={{ backgroundColor: dark ? undefined : alpha(theme.palette.primary.main, 0.05) }}
      {...props}
    >
      <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography variant="h6" textAlign="left" gutterBottom={isMobile ? true : false}>
          {headline}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" textAlign="left">
          {desc}
        </Typography>
        {href || to
          ? (
              <ButtonEx {...asButtonHrefOrToProps({ href, to })} target={href ?? '_blank'}>
                {linkText ?? 'Learn More'}
              </ButtonEx>
            )
          : null}
      </AccordionDetails>
    </Accordion>
  )
}
