import type {
  FunnelStartedFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'

// import DripEventHandler from './DripEventHandler.tsx'
import FacebookEventHandler from './FacebookEventHandler.ts'
import GoogleEventHandler from './GoogleEventHandler.ts'
// import RedditEventHandler from './RedditEventHandler.tsx'
// import SnapchatEventHandler from './SnapchatEventHandler.tsx'
// import TiktokEventHandler from './TiktokEventHandler.tsx'
import CoinEventHandler from './XyEventHandler.ts'

class UserEvents<T extends Record<string, unknown>> extends UserEventHandler<T> {
  static instance: UserEvents<Record<string, unknown>>

  protected handlers: UserEventHandler<T>[]

  private constructor(handlers?: UserEventHandler<T>[]) {
    super()
    this.handlers = handlers ?? [
      new CoinEventHandler<T>(),
      new FacebookEventHandler<T>(),
      new GoogleEventHandler<T>(),
      // new DripEventHandler<T>(),
      // new TiktokEventHandler<T>(),
      // new RedditEventHandler<T>(),
      // new SnapchatEventHandler<T>(),
    ]
  }

  static get() {
    this.instance = this.instance ?? new UserEvents()
    return this.instance
  }

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await Promise.allSettled(this.handlers.map(handler => handler.userClick(fields)))
  }

  async viewContent(fields: ViewContentFields) {
    await Promise.allSettled(this.handlers.map(handler => handler.viewContent(fields)))
  }
}

export default UserEvents
