import { RedirectWithQuery } from '@xylabs/sdk-react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { BrandPage } from './Brand/index.ts'
import { CoinAppPage } from './CoinApp/index.ts'
import { CompanyPage } from './Company/index.ts'
import { DataismPage } from './Dataism/index.ts'
import { FilingsPage } from './Filings/index.ts'
import { FutureProjectsPage } from './FutureProjects/index.ts'
import { GetStartedPage } from './GetStarted/index.ts'
import { HistoryPage } from './History/index.ts'
import { HomePage } from './Home/index.ts'
import { InvestorRoutes } from './Investors/index.ts'
import { JobsRoutes } from './Jobs/index.ts'
import { MissionPage } from './Mission/index.ts'
import { NewsPage } from './News/index.ts'
import { PrivacyPage } from './Privacy/index.ts'
import { ProductsPage } from './Products/index.ts'
import { SalesTermsPage } from './SalesTerms/index.ts'
import { SweepstakesRulesPage } from './SweepstakesRules/index.ts'
import { TeamPage } from './Team/index.ts'
import { TermsPage } from './Terms/index.ts'
import { XyFinditPage } from './XyFindit/index.ts'
import { XyoNetworkPage } from './XyoNetwork/index.ts'

export const SiteRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Jobs Pages */}
      <Route element={<JobsRoutes />} path="/jobs/*" />
      <Route element={<CompanyPage />} path="/company" />
      {/* Team Page & Redirect */}
      <Route element={<TeamPage />} path="/company/team" />
      <Route element={<RedirectWithQuery to="/company/team" />} path="/team" />
      {/* Mission Page & Redirect */}
      <Route element={<MissionPage />} path="/company/mission" />
      <Route element={<RedirectWithQuery to="/company/mission" />} path="/mission" />
      {/* History Page & Redirect */}
      <Route element={<HistoryPage />} path="/company/history" />
      <Route element={<RedirectWithQuery to="/company/history" />} path="/history" />
      {/* Roadmap Page & Redirect */}
      {/* <Route element={<RoadmapPage />} path="/company/roadmap" />
      <Route element={<RedirectWithQuery to="/company/roadmap" />} path="/roadmap" /> */}
      {/* Product Pages */}
      <Route element={<ProductsPage />} path="/products" />
      <Route element={<CoinAppPage />} path="/products/coin" />
      <Route element={<XyoNetworkPage />} path="/products/xyo-network" />
      <Route element={<XyFinditPage />} path="/products/xy-find-it" />
      <Route element={<FutureProjectsPage />} path="/products/future" />
      {/* Investor Pages & Redirects */}
      <Route element={<InvestorRoutes />} path="/investors/*" />
      {/* Legal Pages */}
      <Route element={<PrivacyPage />} path="/privacy" />
      <Route element={<TermsPage />} path="/terms" />
      <Route element={<SalesTermsPage />} path="/sales-terms" />
      <Route element={<SweepstakesRulesPage />} path="/sweeps" />
      {/* Other Pages */}
      <Route element={<DataismPage />} path="/dataism" />
      <Route element={<GetStartedPage />} path="/get-started" />
      <Route element={<BrandPage />} path="/brand" />

      <Route element={<FilingsPage />} path="/filings" />
      <Route element={<NewsPage />} path="/news" />
      <Route element={<HomePage />} path="/" />
      <Route element={<RedirectWithQuery to="/" />} path="/*" />
    </Routes>
  )
}
