import type { BoxProps } from '@mui/material'
import { Typography } from '@mui/material'
import { FlexCol, LinkEx } from '@xylabs/sdk-react'
import React from 'react'

const Headquarters: React.FC<BoxProps> = (props) => {
  return (
    <FlexCol {...props}>
      <Typography variant="h6">Company</Typography>
      <LinkEx color="inherit" to="/company/mission">
        Mission &amp; Values
      </LinkEx>
      <LinkEx color="inherit" to="/company/team">
        Meet the Team
      </LinkEx>
      <LinkEx color="inherit" to="/company/history">
        History
      </LinkEx>
      <LinkEx color="inherit" to="/news">
        News
      </LinkEx>
    </FlexCol>
  )
}

export default Headquarters
