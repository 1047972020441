import type { Organization } from '@xylabs/sdk-react'

export const XyLabsOrganizationPartial: Organization = {
  '@type': 'Organization',
  'foundingDate': '2012',
  'image': 'https://xylabs.com/img/XYLabs_Logo_Full.png',
  'logo': 'https://xylabs.com/img/XYLabs_Logo_Full.png',
  'name': 'XY Labs, Inc',
  'owns': ['https://coinapp.co/organization', 'https://coinapp.co/sentinelx/nfc'],
  'slogan': 'Data Without Doubt',
  'telephone': '+1-866-200-5685',
  'url': 'https://xylabs.com',
}
