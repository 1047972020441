import UserEvents from './UserEvents.ts'

// we call this outside the hook to force initialization on page load
const instance = UserEvents.get()

const getUserEvents = () => {
  return instance
}

export { getUserEvents }

export { default as UserEventId } from './UserEventId.ts'
export { default as UserEvents } from './UserEvents.ts'
