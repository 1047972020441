import {
  Container, Grid, Typography, useTheme,
} from '@mui/material'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import {
  ButtonEx, FlexGrowCol, FlexGrowRow,
} from '@xylabs/sdk-react'
import React from 'react'

import { PlantsRightParallax } from '../../common/index.ts'

export const InvestorSection: React.FC<BusyBoxProps> = ({ ...props }) => {
  const theme = useTheme()
  const color1 = theme.palette.primary.main
  const color2 = theme.palette.primary.dark
  return (
    <FlexCol
      alignItems="stretch"
      height="100vh"
      {...props}
      sx={{
        backgroundColor: `linear-gradient(120deg, ${color1}, ${color2})`,

        backgroundPosition: { lg: 'bottom', xs: 'bottom' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { md: 'cover', xs: 'contain' },
        maxHeight: { md: '800px' },
      }}
    >
      <FlexGrowCol
        sx={{
          display: { lg: 'flex', xs: 'none' }, height: { xs: '100%' }, maxHeight: '1000px',
        }}
        width="100%"
        position="absolute"
      >
        <PlantsRightParallax />
      </FlexGrowCol>
      <Container>
        <FlexCol sx={{ height: { xs: '100vh' }, maxHeight: { xs: '1000px' } }}>
          <Grid container justifyContent="space-between" alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={6}>
              <FlexGrowCol alignItems="flex-start">
                <Typography variant="h2" textAlign="left" gutterBottom>
                  Invest
                </Typography>
                <Typography variant="h6" gutterBottom textAlign="left">
                  XY Labs Class A common stock is traded on tZERO ATS, a FINRA member broker dealer that operates an SEC-regulated &quot;Alternative
                  Trading System&quot; that facilitates trading of digital securities.
                </Typography>
                <Typography variant="h6" gutterBottom textAlign="left">
                  XY Labs files semi-annually with the SEC, providing transparency for both its investors and any who join the XY Labs&apos; journey.
                </Typography>
                <FlexGrowRow marginTop={3} gap={2}>
                  <ButtonEx href="https://www.tzero.com/investors/register" variant="contained" color="primary">
                    Create a tZERO Account
                  </ButtonEx>
                  <ButtonEx to="/investors" variant="outlined" color="primary">
                    Learn More
                  </ButtonEx>
                </FlexGrowRow>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </FlexCol>
      </Container>
    </FlexCol>
  )
}
