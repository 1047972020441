/* eslint-disable @stylistic/max-len */
import type { SimpleCardProps } from '@xyo-network/react-card'

const InvestorResourcesData: SimpleCardProps[] = [
  {
    desc: "Vertalo is a technology-first transfer agent that offers an improved shareholder experience, digital communications. Most importantly, Vertalo will be integrated with tZERO and will allow view-through access to shareholders who trade on tZERO. We are working directly with Vertalo to move Shareholder accounts from our previous transfer agent, 'Broadridge', to Vertalo's system.",
    headline: 'Vertalo',
    to: '/investors/portal',
  },
  {
    desc: 'Established in 2003, tZERO ATS is a FINRA member broker dealer which operates an SEC-registered Alternative Trading System that facilitates trading of security tokens and US equities alike. tZERO ATS offers cryptocurrency trading, as well as a digital securities trading platform, called tZERO Markets. XY Labs is working with tZERO to digitize XY Shares for the tZERO Markets Platform.',
    headline: 'tZERO ATS',
    href: 'https://www.tzero.com/',
  },
  {
    desc: 'If you need more information about your XY holdings, this is the place to begin. A complete compendium of relevent articles, your piece of the XY help center has information about how to get in touch with our transfer agent, frequently asked questions, and more. Need to get in touch with XY? Just submit a request!',
    headline: 'XY Company',
    href: 'https://support.xy.company/hc/en-us/sections/360006409073',
  },
  {
    desc: 'The U.S. Securites and Exchange Commission has been involved in every part of the XY equity process, approving our offering, share prices, and requiring regular reports be made to the public and to our shareholders. These filings can give you detailed insights into the trajectory and future of XY Labs.',
    headline: 'SEC Filings',
    to: '/filings',
  },
]

export default InvestorResourcesData
