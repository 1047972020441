import { Typography } from '@mui/material'
import {
  FlexGrowCol,
  LinkEx,
} from '@xylabs/sdk-react'
import type { ReactNode } from 'react'
import React from 'react'

import {
  Tzero2Fa,
  TzeroApplicationSubmitted,
  TzeroConfirmAccountCreation,
  TzeroCreateAccount,
  TzeroDashboard,
  TzeroIdentity,
  TzeroInvestorDetails,
  TzeroPersonalDetails,
  TzeroReview,
  TzeroSignup,
} from './img/index.ts'
import { TzeroInvestorOptionsAccordion } from './TzeroInvestorOptionsAccordion.tsx'

export interface stepsProps {
  description: ReactNode
  image: string
  label: string
}

export const stepsData: stepsProps[] = [
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Visit tZERO&apos;s website to make your tZERO Account. You can click the &quot;Get Started&quot; on their website, or visit&nbsp;
          <LinkEx href="https://www.tzero.com/investors/register" target="_blank">
            www.tzero.com/investors/register
          </LinkEx>
          &nbsp; directly, and you&apos;ll see the form to create an account for tZERO Markets.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          If you click &quot;Get Started&quot; and have two options, be sure to select &quot;Digital Securities&quot; to create a tZERO Markets
          account.&nbsp;
          <strong>An account for tZERO&apos;s cryptocurrency app will not work for XY Labs Stock.</strong>
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroSignup,
    label: 'Visit tZERO Website',
  },
  {
    description: (
      <FlexGrowCol alignItems="center">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Next, you&apos;ll enter in some standard details to create an account. Be sure to use an email you check regularly and have access to.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroCreateAccount,
    label: 'Create an Account',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Next, you&apos;ll need to add and verify a phone number for your account. This is required because it adds an extra layer of protection to
          your account. If you live in North America, you can verify your phone number via SMS.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          If you are outside of North America, you&apos;ll need to verify with Google Authenticator. Google Authenticator is an application you can
          download on a computer or phone. View the authenticator here:&nbsp;
          <LinkEx href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
            Google Authenticator
          </LinkEx>
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          <strong>Don&apos;t have a phone number?</strong>
&nbsp; Try downloading the Google Authenticator app on a tablet device connected to the
          Apple App Store or Google Play Store.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          <i>Note: XY Labs cannot assist users if they do not have a phone number or device capable of using Google Authenticator.</i>
        </Typography>
      </FlexGrowCol>
    ),
    image: Tzero2Fa,
    label: 'Verify Your Account',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Here, you&apos;ll provide some basic information about yourself. This is standard for products that allow you to trade stock, such as
          Robinhood, Merill Lynch, Schwab, etc.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroPersonalDetails,
    label: 'Provide Personal Details',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          After entering in your details, you need to verify your identity with a form of ID. In many cases, this will be a government-issued ID, like
          a driver&apos;s license.
        </Typography>
        <Typography variant="body1" textAlign="left" gutterBottom>
          You will also most likely have to provide a SSN or Tax ID. This is also industry-standard for stock-trading service platforms.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroIdentity,
    label: 'Confirm Your Identity',
  },
  {
    description: <TzeroInvestorOptionsAccordion />,
    image: TzeroInvestorDetails,
    label: 'Confirm Your Investor Details',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Double-check your information, and make sure everything looks correct.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroReview,
    label: 'Review your Details',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          After confirming your information, you need to agree to tZERO&apos;s policies and agreements.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroConfirmAccountCreation,
    label: 'Confirm Account Creation',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Once you have confirmed everything is correct and confirmed your account creation, your application will be submitted! tZERO Markets will
          review your application, and send an email once it has been approved or if additional information is required.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroApplicationSubmitted,
    label: 'Submit Your Application',
  },
  {
    description: (
      <FlexGrowCol alignItems="flex-start">
        <Typography variant="body1" textAlign="left" gutterBottom>
          Congrats! You&apos;ve made the first step towards being able to trade XY Labs Stock on tZERO Markets. XY Labs Stock is currently in the
          process of being listed on tZERO Markets. We expect to list sometime in the upcoming weeks.
        </Typography>
      </FlexGrowCol>
    ),
    image: TzeroDashboard,
    label: 'Trade with tZERO',
  },
]
