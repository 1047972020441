/* eslint-disable @stylistic/max-len */
import type { SimpleCardProps } from '@xyo-network/react-card'

const InvestorResourcesData: SimpleCardProps[] = [
  {
    desc: 'XY equity can only be privately traded at this time, meaning that its value is determined by the buyer and seller in a private arrangement.    ',
    headline: 'What is my XY Equity worth?',
    href: 'https://support.xy.company/hc/en-us/articles/360018875154',
  },
  {
    desc: "Broadridge assists with inquiries regarding your Broadridge account set up, transferring shares to a new owner, and trouble with your Broadridge account. All other investment-related inquiries should go to XY's team.",
    headline: 'How do I contact Broadridge?    ',
    href: 'https://www.tzero.com/',
  },
  {
    desc: 'No, XY is privately traded and share prices are determined by the seller and buyer. If this status is subject to change, all shareholders will be publicly notified at once, per SEC regulations.',
    headline: 'Is XY listed on an exchange?    ',
    href: 'https://support.xy.company/hc/en-us/sections/360006409073',
  },
  {
    desc: "You'll need to begin by arranging a private buyer for your shares, and then once the sale is complete, transfer your shares to the new owner through Broadridge.",
    headline: 'How do I sell my shares?    ',
    to: './filings',
  },
  {
    desc: 'No need to worry. There are multiple proofs of purchase for your transaction and you are in our records. Please contact us if you would like an electronic copy or another proof of purchase.    ',
    headline: 'I lost my investment certificate!',
    to: './filings',
  },
  {
    desc: "We have an answer. Please take a moment to puruse investor questions in our help center, and if you need further assistance, just use the'Submit a Request' button to get in touch with our specialists.",
    headline: 'I have another question.    ',
    to: './filings',
  },
]

export default InvestorResourcesData
