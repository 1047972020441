import {
  Card, CardActions, CardContent, Typography,
} from '@mui/material'
import {
  FlexCol, FlexGrowCol, FlexRow,
} from '@xylabs/sdk-react'
import React from 'react'

import TeamBioDialog from './BioDialog.tsx'
import { ComingSoon } from './img/index.ts'
import type TeamMemberProps from './teamMemberProps.tsx'

const TeamCard: React.FC<TeamMemberProps> = (props) => {
  const {
    image, name, role,
  } = props

  return (
    <FlexCol justifyContent="flex-start" alignItems="stretch" margin={2} height="85%">
      <Card
        elevation={0}
        style={{
          borderRadius: '12px',
          marginTop: '10%',
          minHeight: '100%',
          paddingTop: '85%',
        }}
      >
        <FlexGrowCol height="100%" justifyContent="space-between">
          <CardContent>
            <FlexCol alignItems="center" paddingTop={1}>
              <Typography variant="h6" textAlign="center" gutterBottom sx={{ paddingTop: { md: 0, xs: 2 } }}>
                {name}
              </Typography>
              <Typography variant="subtitle2" textAlign="center" gutterBottom>
                {role}
              </Typography>
            </FlexCol>
          </CardContent>
          <CardActions>
            <FlexRow alignItems="flex-end" width="100%" padding={2}>
              <TeamBioDialog {...props} />
            </FlexRow>
          </CardActions>
        </FlexGrowCol>
      </Card>
      <FlexCol width="100%" position="absolute">
        <FlexRow
          width="86%"
          paddingTop="100%"
          style={{
            backgroundImage: image ? `url(${image})` : `url(${ComingSoon})`,
            backgroundPosition: 'top',
            backgroundSize: 'cover',
            borderRadius: '12px',
          }}
        />
      </FlexCol>
    </FlexCol>
  )
}

export default TeamCard
