/* eslint-disable @stylistic/max-len */
import { CommonLogos } from '../../common/index.ts'
import {
  CoinPhonesPng, CoinPhonesWebp, XyFindItPng, XyFindItWebp, XyoScreenPng, XyoScreenWebp,
} from './img/index.ts'

interface ProductsProps {
  desc: string
  fullLogo: string
  iconLogo: string
  imagePng: string
  imageWebp: string
  link: string
  name: string
}

export const ProductsData: ProductsProps[] = [
  {
    desc: 'Simply put, XYO is a self-sovereign data platform that allows people to make dApps without consensus. A dApp is any application (web or mobile) that has a decentralized or cryptographic component. Our XYO technology both improves the inherent quality of data, through cryptographic',
    fullLogo: CommonLogos.XYOLogoColor,
    iconLogo: CommonLogos.XYOIconLogoColor,
    imagePng: XyoScreenPng,
    imageWebp: XyoScreenWebp,
    link: '/products/xyo-network',
    name: 'XYO',
  },
  {
    desc: "COIN is an app that rewards you for your time, attention, and data. As XY's first XYO-enabled dApp, COIN has enabled XYO technology to be used worldwide, in millions of devices.",
    fullLogo: CommonLogos.coinLogoColor,
    iconLogo: CommonLogos.coinIconLogoColor,
    imagePng: CoinPhonesPng,
    imageWebp: CoinPhonesWebp,
    link: '/products/coin',
    name: 'COIN',
  },
  {
    desc: "The app that keeps all the things you love, a little closer. XY Find It technology lets you use your phone to quickly find the things you misplace most - your keys, the remote, or the kids' backpacks.",
    fullLogo: CommonLogos.XYFindItColor,
    iconLogo: CommonLogos.XYFindItIconColor,
    imagePng: XyFindItPng,
    imageWebp: XyFindItWebp,
    link: '/products/xy-find-it',
    name: 'XY Find It',
  },
]
