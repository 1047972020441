import { alpha, useTheme } from '@mui/material'
import type { AppBarExProps } from '@xylabs/sdk-react'
import { AppBarEx, FlexCol } from '@xylabs/sdk-react'
import React from 'react'

import ContextToolbar from './ContextToolbar.tsx'
import SystemToolBar from './SystemToolbar/index.ts'

const AppBar: React.FC<AppBarExProps> = ({
  contextToolbar, systemToolbar, ...props
}) => {
  const theme = useTheme()

  return (
    <FlexCol width="100%">
      <AppBarEx
        style={{
          backdropFilter: 'blur(20px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.6),
          color: theme.palette.getContrastText(theme.palette.background.paper),
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1),
        }}
        container="xl"
        contextToolbar={contextToolbar ?? <ContextToolbar />}
        systemToolbar={systemToolbar ?? <SystemToolBar />}
        {...props}
      />
    </FlexCol>
  )
}

export default AppBar
