import { CoinLogo, XyoLogo } from '../img/index.ts'
import type OpportunityProps from './OpportunityProps.tsx'

const operationsSupportOpportunities: OpportunityProps[] = [
  {
    compensation: '$80k+',
    desc: '',
    experience: '2+ years',
    jobTitle: 'BI Analyst',
    logos: [CoinLogo, XyoLogo],
    tools: 'BigQuery, Excel',
  },
  {
    compensation: '$100k+',
    desc: '',
    experience: '2+ years',
    jobTitle: 'Automation / BI / Integration Engineer',
    logos: [CoinLogo],
    tools: 'ZenDesk, Shipstation, Drip, Zapier, Webhooks, StitchData, MongoDB, BigQuery',
  },
]

export default operationsSupportOpportunities
