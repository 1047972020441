import { PlayCircleOutlineRounded } from '@mui/icons-material'
import {
  Container, Grid, Typography,
} from '@mui/material'
import type { BusyBoxProps } from '@xylabs/sdk-react'
import {
  ButtonEx, FlexCol, FlexGrowCol, FlexGrowRow,
} from '@xylabs/sdk-react'
import React from 'react'

const onClickIdScrollIntoView = (elementId: string) => () => {
  const section = document.querySelector(elementId)
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

export const HomePageDataSection: React.FC<BusyBoxProps> = () => {
  return (
    <Container>
      <FlexCol sx={{ height: { xs: '100vh' }, maxHeight: { xs: '1000px' } }}>
        <Grid container justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h2" textAlign="left">
              A Data-Driven, Cryptographic, Company
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexGrowCol alignItems="flex-start">
              <Typography variant="h6" gutterBottom textAlign="left">
                Data should be easily accessible, rightfully owned, and widely trusted by those who use it.
              </Typography>
              <Typography variant="h6" gutterBottom textAlign="left">
                XY Labs revolutionizes how data is used in society by providing self-sovereign data gathering, storage, and processing solutions that
                maintains data provenance without increased cost or decreased performance.
              </Typography>
              <FlexGrowRow marginTop={3} gap={2}>
                <ButtonEx onClick={onClickIdScrollIntoView('#products')} variant="contained" color="primary">
                  Our Products
                </ButtonEx>
                <ButtonEx variant="outlined" disabled color="primary">
                  Video Coming Soon!&nbsp;
                  {' '}
                  <PlayCircleOutlineRounded />
                </ButtonEx>
              </FlexGrowRow>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </FlexCol>
    </Container>
  )
}
