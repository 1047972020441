import {
  Container, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

const XyoExplained: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={4}>
            <FlexGrowCol>
              <Typography variant={isMobile ? 'h2' : 'h1'} gutterBottom textAlign="left">
                What is XYO Network?
              </Typography>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexGrowCol>
              <Typography variant="body1" gutterBottom textAlign="left">
                In 2018, we unveiled the XYO Network, the world’s first geospatial blockchain network backed with cryptography. XYO is a network of
                devices that anonymously collects and validates geospatial data, or data with a geographic component. The validation of this data is a
                key part of this network protocol, and a major point for understanding its potential benefit for all.
              </Typography>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default XyoExplained
