import { Divider } from '@mui/material'
import React from 'react'

import { BasicHero, CommonImages } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'
import { GetStartedCards } from './Cards.tsx'

export const GetStartedPage: React.FC = () => {
  return (
    <BasePage name="Get Started">
      <BasicHero
        title="Not sure where to start?"
        desc="Looking for something? Click one of the options below to learn more about XY Labs!"
        backgroundImage={CommonImages.floorPlantsWide}
        centeredAlways
      />
      <Divider />
      <GetStartedCards />
    </BasePage>
  )
}
