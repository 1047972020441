import { XyPixel } from '@xylabs/pixel'
import type { PropsWithChildren } from 'react'
import React from 'react'

import PixelContext from './Context.ts'

interface Props {
  id: string
}

const Provider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, id } = props
  XyPixel.init(id)

  return (
    <PixelContext.Provider
      // eslint-disable-next-line @eslint-react/no-unstable-context-value
      value={{ pixel: XyPixel.instance }}
    >
      {children}
    </PixelContext.Provider>
  )
}

export default Provider
