import {
  alpha, Container, Divider, Grid, useTheme,
} from '@mui/material'
import { SimpleCard } from '@xyo-network/react-card'
import { SectionSpacingRow, useIsMobile } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero, CommonImages } from '../../common/index.ts'
import { useSettings } from '../../Contexts/index.ts'
import BasePage from '../BasePage.tsx'
import NewsData from './newsData.ts'

const NewsPage: React.FC = () => {
  const isMobile = useIsMobile()
  const theme = useTheme()
  const darkMode = useSettings()
  return (
    <BasePage name="News">
      <BasicHero
        title="News &amp; Announcements"
        desc="Learn more about what XY Labs has been up to!"
        backgroundImage={isMobile ? '' : CommonImages.twoSatellite}
      />
      <Divider />
      <SectionSpacingRow>
        <Container>
          <Grid container spacing={2}>
            {NewsData.map(({
              headline, date, href, logoLight, logoDark, media,
            }, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4} xl={4}>
                  <SimpleCard
                    sx={{
                      '&:hover': { cursor: 'pointer' },
                      'backgroundColor': alpha(theme.palette.primary.main, 0.05),
                      'display': 'flex',
                      'flexDirection': 'column',
                      'height': '100%',
                    }}
                    media={media}
                    href={href}
                    desc={headline}
                    iconImage={darkMode ? logoLight : logoDark}
                    subtitle={date}
                    interactionVariant="button"
                  />
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default NewsPage
