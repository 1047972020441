import React from 'react'

import { JobsAppBar } from '../AppBar/index.ts'
import BasePage from './BasePage.tsx'
import type { BasePageProps } from './BasePageProps.tsx'

const JobsBasePage: React.FC<BasePageProps> = ({ appBar, ...props }) => {
  return <BasePage appBar={appBar ?? <JobsAppBar />} {...props} />
}

export default JobsBasePage
