import {
  alpha, Container, Typography, useTheme,
} from '@mui/material'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { EmailNewsletterTextField } from './EmailNewsletterTextField.tsx'

export const NewsletterSection: React.FC<BusyBoxProps> = () => {
  const theme = useTheme()
  return (
    <SectionSpacingRow bgcolor={alpha(theme.palette.primary.main, 0.05)}>
      <Container>
        <FlexGrowCol>
          <Typography textAlign="center" gutterBottom variant="body1">
            Stay up to date
          </Typography>
          <Typography paddingBottom={3} gutterBottom textAlign="center" variant="h3">
            Get our newsletter
          </Typography>
          <EmailNewsletterTextField />
        </FlexGrowCol>
      </Container>
    </SectionSpacingRow>
  )
}
