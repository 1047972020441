import { BasicHero } from '@xyo-network/react-shared'
import React from 'react'

import BasePage from '../../BasePage.tsx'

export const InvestorsSupportPage: React.FC = () => {
  return (
    <BasePage name="Investor Support">
      <BasicHero
        title="Investor Relations Team"
        desc=""
        button1Href="https://support.xy.company/hc/en-us/requests/new"
        button1Text="Contact the Team"
      />
    </BasePage>
  )
}
