import {
  Container, Divider, Typography, useTheme,
} from '@mui/material'
import type { FlexBoxProps } from '@xylabs/sdk-react'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { SanDiegoImage } from './img/index.ts'

const Location: React.FC<FlexBoxProps> = (props) => {
  const theme = useTheme()
  return (
    <FlexRow bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText} {...props} sx={{ paddingY: { md: 4, xs: 2 } }}>
      <Container>
        <FlexRow sx={{ flexDirection: { md: 'row', xs: 'column' } }} alignItems="center" justifyContent="space-between">
          <FlexCol alignItems="flex-start" maxWidth={600} marginY={2} marginX={4}>
            <Typography flexWrap="wrap" variant="h2" component="h1" gutterBottom>
              Founded in San Diego, Fully Remote Team
            </Typography>
            <Divider style={{ width: 120 }} />
            <Typography flexWrap="wrap" variant="h6" gutterBottom marginTop={2}>
              With full-time employees and contractors in the United States, Europe, and Asia, XY Labs boasts a decentralized team building
              data-centric products and platforms.
            </Typography>
          </FlexCol>
          <FlexCol>
            <img height="auto" width="100%" src={SanDiegoImage} alt="San Diego" />
          </FlexCol>
        </FlexRow>
      </Container>
    </FlexRow>
  )
}

export default Location
