import { Toolbar } from '@mui/material'
import { ButtonEx } from '@xylabs/sdk-react'
import React from 'react'

import { XyLogos } from '../common/index.ts'
import { useSettings } from '../Contexts/index.ts'

const ContextToolbar: React.FC = () => {
  const { darkMode } = useSettings()
  return (
    <Toolbar disableGutters>
      <ButtonEx
        to="/"
        startIcon={<img src={darkMode ? XyLogos.XYLabs_Logo_Full_BrandWhite_SVG : XyLogos.XYLabs_Logo_Full_BrandBlack_SVG} height={50} />}
      >
      </ButtonEx>
    </Toolbar>
  )
}

export default ContextToolbar
