import {
  Container, Divider, Grid,
} from '@mui/material'
import { FlexGrowRow } from '@xylabs/sdk-react'
import { SimpleCard } from '@xyo-network/react-card'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'
import FutureProjectsData from './FutureProjectsData.tsx'

const FutureProjectsPage: React.FC = () => {
  return (
    <BasePage name="Future Projects">
      <BasicHero title="Future Projects" desc="When we're not working on our big-name projects, we spend time developing new things!" />
      <Divider />
      <FlexGrowRow>
        <Container>
          <SectionSpacingRow>
            <Grid container spacing={2}>
              {FutureProjectsData.map((project, index) => {
                return (
                  <Grid item key={index} xs={12} md={4} lg={4}>
                    <SimpleCard headline={project.headline} desc={project.desc} href={project.href} sx={{ height: '100%' }} />
                  </Grid>
                )
              })}
            </Grid>
          </SectionSpacingRow>
        </Container>
      </FlexGrowRow>
    </BasePage>
  )
}

export default FutureProjectsPage
