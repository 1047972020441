import {
  Container, Grid, MobileStepper, Typography, useTheme,
} from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import type { FC } from 'react'
import React, { useState } from 'react'
import type { SwipeableViewsProps } from 'react-swipeable-views'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const TypedSwipeableViews = SwipeableViews as unknown as FC<SwipeableViewsProps>

const CoinQuote: React.FC = () => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  const AutoPlaySwipeableViews = autoPlay(TypedSwipeableViews)

  return (
    <SectionSpacingRow bgcolor={theme.palette.primary.dark}>
      <Container>
        <Grid container alignContent="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={7000}
            >
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; At first it was getting into the crypto world with&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>no investment</span>
                  {' '}
                  and also earn while doing normal everyday functions like
                  going to work, or anywhere really, then even when I’m sleeping,&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>my phone is making money.</span>
                  {' '}
                  &quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - Tyler H replying to “What brought you to COIN?” (Missouri, US)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; Interested in crypto but was hesitant to touch my normal savings so it was a&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>no-brainer</span>
                  .&quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - Lee replying to &quot;What brought you to COIN?&quot; (Ireland/Italy)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; We bought our first car together, with a $15,000 down payment.&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>All paid for by COIN.</span>
                  {' '}
                  &quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - N.B., CANADA
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; ...my participation with COIN (and XYO) has quite literally resulted in&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>life altering, positive changes.</span>
                  {' '}
                  For example, I am 5 years in on a
                  30-year mortgage and through COIN/XYO&nbsp;
                  <span style={{ color: theme.palette.secondary.main }}>I could pay it all off tomorrow</span>
                  {' '}
                  if I wanted to.&quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - T.T., Software Engineer (New Hampshire, US)
                </Typography>
              </FlexCol>
              <FlexCol width="100%" alignItems="flex-start">
                <Typography variant="h2" color={theme.palette.primary.contrastText} gutterBottom>
                  &quot; “Didn&apos;t know NOTHING about crypto until I started using the COIN app back in June or so of 2019...
                  {' '}
                  <span style={{ color: theme.palette.secondary.main }}>now I&apos;m in for the win.</span>
                  &quot;
                </Typography>
                <Typography variant="h6" color={theme.palette.primary.contrastText}>
                  - James Taapken, (Illinois, US)
                </Typography>
              </FlexCol>
            </AutoPlaySwipeableViews>
            <FlexRow justifyContent="center">
              <MobileStepper
                steps={5}
                style={{ backgroundColor: 'transparent' }}
                position="static"
                activeStep={activeStep}
                nextButton={null}
                backButton={null}
              />
            </FlexRow>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default CoinQuote
