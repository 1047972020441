import { ArrowRightAltRounded } from '@mui/icons-material'
import {
  Card, Container, Grid, Typography,
} from '@mui/material'
import { useWebP } from '@xylabs/react-shared'
import {
  ButtonEx, FlexCol, FlexGrowCol, FlexRow,
} from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React, { useState } from 'react'

import { ProductsData } from './ProductsData.tsx'

export const ProductsCarousel: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState(0)
  const background = useWebP(ProductsData[selectedProduct].imageWebp, ProductsData[selectedProduct].imagePng)
  return (
    <SectionSpacingRow
      sx={{
        backgroundPosition: { lg: 'bottom', xs: 'bottom center' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { md: 'cover', xs: 'contain' },
        minHeight: { md: '700px', xs: '600px' },
      }}
      alignItems="stretch"
    >
      <Container>
        <Grid container justifyContent="space-between" alignItems="stretch" height="100%" spacing={2} wrap="wrap-reverse">
          <Grid item xs={12} md={5}>
            <FlexRow width="100%" sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }} paddingBottom={2}>
              {ProductsData.map((props, index) => {
                return (
                  <FlexCol
                    sx={{
                      '&:hover': { cursor: 'pointer' },
                      'marginLeft': { md: 0, xs: 0.5 },
                      'marginRight': { md: 1, xs: 0.5 },
                    }}
                    onClick={() => setSelectedProduct(index)}
                    key={index}
                  >
                    <Card elevation={selectedProduct == index ? 1 : 0}>
                      <FlexCol
                        height="35px"
                        margin={1}
                        width="35px"
                        sx={{
                          backgroundImage: `url(${selectedProduct == index ? props.iconLogo : props.iconLogo})`,
                          backgroundPosition: 'left',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          opacity: selectedProduct == index ? '100%' : '50%',
                        }}
                      >
                      </FlexCol>
                    </Card>
                  </FlexCol>
                )
              })}
            </FlexRow>
            <FlexGrowCol sx={{ alignItems: { md: 'flex-start', xs: 'center' } }}>
              <Typography variant="h2" sx={{ textAlign: { md: 'left', xs: 'center' } }} gutterBottom>
                {ProductsData[selectedProduct].name}
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ textAlign: { md: 'left', xs: 'center' } }} paddingBottom={1}>
                {ProductsData[selectedProduct].desc}
              </Typography>
              <ButtonEx to={ProductsData[selectedProduct].link} variant="contained" endIcon={<ArrowRightAltRounded />}>
                Learn More
              </ButtonEx>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={12} md={7}>
            <FlexCol
              sx={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: '100%',
                minHeight: '300px',
              }}
            >
            </FlexCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}
