import { QuestionAnswerRounded } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { BasicHero, useMediaQuery } from '@xyo-network/react-shared'
import React from 'react'

import { CommonImages } from '../../../common/index.ts'
import { useSettings } from '../../../Contexts/index.ts'
import XyBasePage from '../../BasePage.tsx'

export const InvestorsHowToBuyPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { darkMode } = useSettings()
  return (
    <XyBasePage name="Investors How To Buy">
      <BasicHero
        title="How to Buy XY Equity"
        desc="Interested in becoming a shareholder? Make a tZERO Markets account to start trading!"
        button1Text="Make an Account"
        button1To="/investors/trade"
        subLinkText1="Need help or have questions?"
        subLinkText2="Get support here."
        subLinkIcon={<QuestionAnswerRounded />}
        backgroundImage={
          isMobile
            ? ''
            : darkMode
              ? CommonImages.defaultDesktopHeaderDark
              : CommonImages.defaultDesktopHeaderLight
        }
        subLinkPath="/investor/support"
      />
    </XyBasePage>
  )
}
