import type { PostalAddress } from '@xylabs/sdk-react'
import { ContactPointOption } from '@xylabs/sdk-react'

export const XyLabsPostalAddress: PostalAddress = {
  '@type': 'PostalAddress',
  'addressCountry': 'US',
  'addressLocality': 'San Diego',
  'addressRegion': 'California',
  'contactOption': ContactPointOption.TollFree,
  'name': 'XY Labs, Inc Headquarters',
  'postalCode': '92101',
  'streetAddress': '740 13th Street #224',
  'telephone': '+1-866-200-5685',
}
