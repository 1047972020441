import {
  alpha, Button, ButtonGroup, Card, CardActions, CardContent, Divider, MenuItem, TextField, Typography, useTheme,
} from '@mui/material'
import { ButtonEx, FlexCol } from '@xylabs/sdk-react'
import * as React from 'react'

import { useSettings } from '../../Contexts/index.ts'
import { XYLabs_Logo_Full_BrandBlack_PNG } from './img/index.ts'
import {
  assetOptions, colors, fileTypes,
} from './LogoData.tsx'

export const LogoSelection: React.FC = () => {
  const { darkMode } = useSettings()
  const [fileType, setFileType] = React.useState('svg')
  const [color, setColor] = React.useState(darkMode ? 'brandWhite' : 'brandBlack')
  const [logoStyle, setLogoStyle] = React.useState('full')
  const theme = useTheme()
  const asset = assetOptions[logoStyle][fileType][color]

  const handleChangeFileType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileType(event.target.value)
  }

  const handleChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value)
  }

  const onDownload = (downloadedItem: string) => {
    const link = document.createElement('a')
    link.download = downloadedItem
    link.href = downloadedItem
    link.click()
  }

  return (
    <Card sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.05) }} elevation={0}>
      <CardContent>
        <FlexCol justifyContent="stretch" gap={2}>
          <img width="auto" style={{ maxHeight: '100px' }} height="auto" src={asset} alt={XYLabs_Logo_Full_BrandBlack_PNG} />
          <Typography variant="caption" gutterBottom>
            Preview
          </Typography>
          <Divider />
          <ButtonGroup variant="outlined" aria-label="logo style options" fullWidth>
            <Button onClick={() => setLogoStyle('full')}>Full Logo</Button>
            <Button onClick={() => setLogoStyle('simple')}>Shortened</Button>
            <Button onClick={() => setLogoStyle('icon')}>Icon</Button>
          </ButtonGroup>
          <TextField
            fullWidth
            id="select-file-type"
            select
            variant="filled"
            defaultValue={fileType}
            value={fileType}
            onChange={handleChangeFileType}
            helperText="Please select your file type"
          >
            {fileTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            id="select-color-type"
            select
            variant="filled"
            value={color}
            defaultValue={color}
            onChange={handleChangeColor}
            helperText="Please select the color of your logo"
          >
            {colors.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FlexCol>
      </CardContent>
      <CardActions>
        <ButtonEx onClick={() => onDownload(asset)}>Download File</ButtonEx>
      </CardActions>
    </Card>
  )
}
