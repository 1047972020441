import { Container } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'
import {
  SweepstakesRules1, SweepstakesRules2, SweepstakesRules3, SweepstakesRules4, SweepstakesRules5, SweepstakesRules6,
} from './img/index.ts'

const SweepstakesRulesPage: React.FC = () => {
  return (
    <BasePage name="Sweepstakes Rules">
      <BasicHero title="Sweepstakes Rules" desc="" />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            <img src={SweepstakesRules1} width="100%" />
            <img src={SweepstakesRules2} width="100%" />
            <img src={SweepstakesRules3} width="100%" />
            <img src={SweepstakesRules4} width="100%" />
            <img src={SweepstakesRules5} width="100%" />
            <img src={SweepstakesRules6} width="100%" />
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default SweepstakesRulesPage
