import type {
  FunnelStartedFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { EmptyObject } from '@xylabs/sdk-js'
import type { GoogleCartProperties } from '@xylabs/sdk-react'

import type CustomProperties from './CustomProperties.tsx'
import GoogleEvents from './Google/index.ts'

class GoogleEventHandler<T extends EmptyObject> extends UserEventHandler<T> {
  private events = new GoogleEvents<CustomProperties | GoogleCartProperties>()

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await this.events.userClick().send(fields as any)
  }

  async viewContent(fields: ViewContentFields) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await this.events.viewContent().send(fields as any)
  }
}

export default GoogleEventHandler
