/* eslint-disable @stylistic/max-len */
import type { SimpleCardProps } from '@xyo-network/react-card'

const FutureProjectsData: SimpleCardProps[] = [
  {
    desc: 'Peer-to-peer cross-platform data sharing, using the XYO Protocol as a basis. Think "Airdrop" but cross-platform. Additionally, it allows you to optionally share a predefined set of data all the time, such as contact info, photos, etc. Any enabled device, not just your phone, can be a host.',
    headline: 'Projector',
    href: '',
  },
  {
    desc: 'Many applications have trouble detecting location and data spoofers. UnSpoof allows you to detect spoofers in real-time, or with after-the-fact analysis using decentralized XYO Network data. UnSpoof works on existing data and takes spoof detection to another level when also using the UnSpoof SDK client.',
    headline: 'UnSpoof',
    href: '',
  },
  {
    desc: 'xyLocate is the evolution of findability. Instead of just tracking your known items, xyLocate also allows you to locate different types of Bluetooth and WiFi devices in the real world. Did you lose you PS4 controller somewhere in your living room? xyLocate to the rescue.',
    headline: 'xyLocate',
    href: '',
  },
  {
    desc: 'dAuth takes the concepts of OAuth and brings them into the crypto and blockchain realm. With dAuth, you can do accountless authentication, have unchangable signin history, and also multi-sig authentication.',
    headline: 'dAuth',
    href: '',
  },
  {
    desc: '2FA has made the online world a much safer place. With 2FA+, another leap forward can be taken. Based on the XYO Protocol, simple same machine verification, multi-party 2FA, and multi-signin requirements can be met. Best yet, 2FA+ is also backward compatible to standard 2FA.',
    headline: '2FA+',
    href: '',
  },
  {
    desc: 'Automate either smart contracts or traditional cloud functions. This includes XYO-based features like "Transact on Bound Witness", but can be used for many different heuristics. Think IFTTT or Zapier, but decentralized.',
    headline: 'xyAutomate',
    href: '',
  },
]

export default FutureProjectsData
