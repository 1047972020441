import { useContext } from 'react'

import Context from './Context.ts'

const useSettings = () => {
  const settings = useContext(Context)
  return { ...settings }
}

export default useSettings
