import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { SettingsPage } from './Settings/index.ts'
import { SiteRoutes } from './SiteRoutes.tsx'

export const AppRoutes: React.FC = () => {
  const { hostname } = globalThis.location
  useEffect(() => {
    if (hostname.endsWith('jobs.xylabs.com')) {
      globalThis.location.href = 'https://xylabs.com/jobs'
    }
  }, [hostname])
  return (
    <Routes>
      <Route element={<SettingsPage />} path="/settings" />
      <Route element={<SiteRoutes />} path="/*" />
    </Routes>
  )
}
