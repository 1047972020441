import { DarkModeRounded, LightModeRounded } from '@mui/icons-material'
import { IconButton, Zoom } from '@mui/material'
import { FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import { useSettings } from '../Contexts/index.ts'
const DarkModeIconButton: React.FC = () => {
  const { darkMode, enableDarkMode } = useSettings()

  const handleDarkModeChange = () => {
    enableDarkMode?.(!darkMode)
  }
  return (
    <FlexRow>
      <IconButton onClick={handleDarkModeChange}>
        {darkMode
          ? (
              <Zoom in={darkMode} style={{ transitionDelay: darkMode ? '500ms' : '0ms' }}>
                <DarkModeRounded />
              </Zoom>
            )
          : (
              <Zoom in={!darkMode} style={{ transitionDelay: darkMode ? '0ms' : '500ms' }}>
                <LightModeRounded />
              </Zoom>
            )}
      </IconButton>
    </FlexRow>
  )
}

export default DarkModeIconButton
