import type {
  FunnelStartedFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { EmptyObject } from '@xylabs/sdk-js'

import type CustomProperties from './CustomProperties.tsx'
import FacebookEvents from './Facebook/index.ts'

class FacebookEventHandler<T extends EmptyObject> extends UserEventHandler<T> {
  private events = new FacebookEvents<CustomProperties>()

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await this.events.userClick().send({ elementName: fields.elementName, elementType: fields.elementType })
  }

  async viewContent(fields: ViewContentFields) {
    await this.events.viewContent().send({ content_category: fields.name })
  }
}

export default FacebookEventHandler
