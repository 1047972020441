import { Divider } from '@mui/material'
import React from 'react'

import { BasicHero, CommonImages } from '../../common/index.ts'
import BasePage from '../BasePage.tsx'
import { ProductsCarousel } from './ProductsCarousel.tsx'

export const ProductsPage: React.FC = () => {
  return (
    <BasePage name="Products">
      <BasicHero
        title="Our Products"
        desc="Location data, blockchain, tracking items, data provenance &amp; certainty... whatever it is, we got it!"
        backgroundImage={CommonImages.floorPlantsWide}
        centeredAlways
      />
      <Divider />
      <ProductsCarousel />
    </BasePage>
  )
}
